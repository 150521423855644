import React from "react";
import { Link, graphql } from "gatsby";
import { get, truncate, isNil } from "lodash";
import { RichText } from "prismic-reactjs";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import RegisterForNewsletter from '../components/register-for-newsletter';
import "./blog.scss";

export const query = graphql`
  query ArticlesQuery {
    prismic {
      allPosts {
        edges {
          node {
            _meta {
              uid
            }
            content
            title
            main_background_image
            main_background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 640, srcSetBreakpoints: [320, 640], quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const _renderImage = post => {
  const mainBackgroundImage = get(post, ['main_background_imageSharp', 'childImageSharp', 'fluid']);
  return (
    isNil(mainBackgroundImage) ? null : (
      <div className="blog-tiles__tile-image-container">
        <Img 
          className="blog-tiles__tile-image" 
          objectFit="cover"
          objectPosition="50% 50%"
          fluid={mainBackgroundImage} 
        />
      </div>
    )
  )
};

const BlogPage = ({ data }) => {
  const edges = get(data, "prismic.allPosts.edges", []);
  return (
    <Layout>
      <SEO title="Blogs" />
      <div className="section section--blog-tiles">
        <div className="section__inner section__inner--blog-tiles">
          <div className="blog-tiles">
            {edges.map(({ node: { title, content, _meta, ...restOfPost } }) => (
              <Link
                key={_meta.uid}
                className="blog-tiles__tile"
                to={`/blog/${_meta.uid}`}>
                {_renderImage(restOfPost)}
                <div className="blog-tiles__tile-content">
                  <h3 className="blog-tiles__tile-title">
                    {RichText.asText(title)}
                  </h3>
                  <p className="blog-tiles__tile-paragraph paragraph--white">
                    {truncate(RichText.asText(content), { length: 75 })}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <RegisterForNewsletter />
    </Layout>
  );
};

export default BlogPage;
